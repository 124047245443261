<template>
  <a-modal v-model="visible" :title="title" centered @ok="close" @cancel="close" @close="close">
    <span v-html="message"></span>
  </a-modal>
</template>

<script>
export default {
  name: "ModalMessage",
  props: {
    title: { type: String, default: () => "" },
    message: { type: String, default: () => "" },
    visible: { type: Boolean, default: () => false },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>
