var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("message", {
    attrs: {
      message: _vm.errorMessage,
      visible: _vm.visible,
      title: _vm.title
    },
    on: { close: _vm.close }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }