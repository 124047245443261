<template>
  <message :message="errorMessage" :visible="visible" :title="title" @close="close" />
</template>

<script>
import Message from "./ModalMessage";
import { mapGetters } from "vuex";

export default {
  name: "NecessaryPaiementMessage",
  components: {
    Message,
  },
  props: {
    title: {
      type: String,
      default: () => "Vous devez payer votre cotisation annuelle",
    },
    visible: { type: Boolean, default: () => false },
  },
  created() {
    this.$store.cache.dispatch("fetchOrganisation");
  },
  computed: {
    errorMessage() {
      let message = `Vous pouvez payer selon les modes de paiement suivants : <br>
                       <ol> 
                       <li> En espèces au niveau du bureau du conseil </li>
                       <li> Par chèque </li> `;

      if (this.compteBancaire) {
        message += `<li> Par versement au compte bancaire : 
                        <b>${this.compteBancaire}</b>
              </li>`;
      }
      if (this.ccp) {
        message += `<li> Par versement au compte CCP :  \n
                        <b>${this.ccp}</b>
              </li>`;
      }
      message += `</ol>`;
      return message;
    },
    ...mapGetters({
      compteBancaire: "getOrganisationCompteBancaire",
      ccp: "getOrganisationCCP",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    }
  },
};
</script>
