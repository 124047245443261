var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: _vm.title, centered: "" },
      on: { ok: _vm.close, cancel: _vm.close, close: _vm.close },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [_c("span", { domProps: { innerHTML: _vm._s(_vm.message) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }